import { React } from 'react';

function CommingSoon(props) {
    const { handelClaimPopup } = props;

    return (

        <div className="main_custom_popup" id="login_popup_main">
            <div className="main_custom_popup_inner">
                <div className="main_custom_popup_header">
                    <div className="header_title"></div>
                    <div className="close_btn_pop" onClick={() => handelClaimPopup(false)}><i class="fa fa-close"></i></div>
                </div>
                <div className="main_custom_popup_inner_body">
                    <div className="login_popup_data">
                        <h1>Coming soon<span class="dot">.</span></h1>
                        <p>Claim SBT</p>
                        <div class="icons">
                            <a href='https://t.me/angelpoop_gang' target='_blank'><img src="https://angelpoop.xyz/assetsvideo/telegram.svg" alt="telegram" /></a>
                            <a href='https://x.com/AngelPoop_meme' target='_blank'><img src="https://angelpoop.xyz/assetsvideo/twitter.svg" alt="twitter" /></a>
                            <a href='https://medium.com/@AngelPoopMeme' target='_blank'><img src="https://angelpoop.xyz/assetsvideo/medium.svg" alt="twitter" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default CommingSoon;