import React from 'react';
import { Routes, Route, BrowserRouter } from "react-router-dom"
import Myheader from './common-layout/App-header';
import Myfooter from './common-layout/App-footer';
import Homepage from './pages/home';
import NotFound from './pages/NotFound';
import ClaimSbt from './pages/ClaimSbt';

function App() {
  return (
    <div className='main_content_root'>
      <Myheader />
      <div className="page_body_content">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/claim-sbt" element={<ClaimSbt />} />
          </Routes>
        </BrowserRouter>
      </div>
      <Myfooter />
    </div>
  );
}
export default App;
