import React, { useEffect, useState, useRef } from 'react';

function ClaimSbt() {
    const [loading, setLoading] = useState(true);
    const videoRef = useRef(null);
    const [step, setStep] = useState(1);

    // const nextStep = () => {
    //     setStep(step + 1);
    // };
    const nextStep = () => {
        setStep(prevStep => prevStep + 1);
    };



    useEffect(() => {
        const video = videoRef.current;
        if (video) {
            video.muted = true;
            video.play().catch((error) => {
                console.log('Autoplay was prevented:', error);
            });
        }
    }, []);
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);
    useEffect(() => {
        if (step === 6) {
            const timer = setTimeout(() => {
                window.location.href = '/';
            }, 10000);
            return () => clearTimeout(timer); 
        }
    }, [step]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (

        <>

            {loading ? (
                <div id="loadings" className="cs_custom_lodar">
                    <div className="inner_div btn_animate">
                        <div class="containerddd">
                            <div class="dot dot-1"></div>
                            <div class="dot dot-2"></div>
                            <div class="dot dot-3"></div>
                        </div>
                    </div>
                </div>
            ) : (<></>)}

            <section className="main_home_page_css">
                <div className='claimsbt'>
                    <video
                        ref={videoRef}
                        preload="auto"
                        playsInline
                        loop
                        controls={false}
                        className='backgroundvideo'
                    >
                        <source src="https://angelpoop.xyz/assetsvideo/parachuteanimation.webm" type="video/webm" />
                        {/* <source src="https://angelpoop.xyz/assetsvideo/parachuteanimation.MP4" type="video/mp4" /> */}
                    </video>
                    <div className='bannercontent'>
                        <div className='container'>
                            {step === 1 && (
                                <div className='airdropcentercontent'>
                                    <h1>Claim your AirDrop Now!</h1>
                                    <span>Find out if you're eligible to participate.</span>
                                    <p>Connect your TON wallet and perform the mentioned tasks to check if you are elligible for AngelPoop airdrop.</p>                                    
                                    <button type='button' onClick={nextStep}>Participate</button>
                                </div>
                            )} {step === 2 && (
                                <div className='airdropcentercontent'>
                                    <h1>Task 1 to claim your airdrop</h1>
                                    <span>Find out if you're eligible to participate.</span>
                                    <p>Connect your TON wallet and perform the mentioned tasks to check if you are elligible for AngelPoop airdrop.</p>
                                    <div className="customcheckboxinsure">
                                        <input type="checkbox" className="inputCheckbox" id="claimcheck" name="claimcheck" value="yes" />
                                        <label htmlFor="claimcheck">Task 1 to claim your airdrop</label>
                                    </div>
                                    <button type='button' onClick={nextStep}>Next</button>
                                </div>
                            )} {step === 3 && (
                                <div className='airdropcentercontent'>
                                    <h1>Task 2 to claim your airdrop</h1>
                                    <span>Find out if you're eligible to participate.</span>
                                    <p>Connect your TON wallet and perform the mentioned tasks to check if you are elligible for AngelPoop airdrop.</p>
                                    <div className="customcheckboxinsure">
                                        <input type="checkbox" className="inputCheckbox" id="claimcheck2" name="claimcheck2" value="yes" />
                                        <label htmlFor="claimcheck2">Task 2 to claim your airdrop</label>
                                    </div>
                                    <button type='button' onClick={nextStep}>Next</button>
                                </div>
                            )} {step === 4 && (
                                <div className='airdropcentercontent'>
                                    <h1>Task 3 to claim your airdrop</h1>
                                    <span>Find out if you're eligible to participate.</span>
                                    <p>Connect your TON wallet and perform the mentioned tasks to check if you are elligible for AngelPoop airdrop.</p>
                                    <div className="customcheckboxinsure">
                                        <input type="checkbox" className="inputCheckbox" id="claimcheck3" name="claimcheck3" value="yes" />
                                        <label htmlFor="claimcheck3">Task 3 to claim your airdrop</label>
                                    </div>
                                    <button type='button' onClick={nextStep}>Next</button>
                                </div>
                            )} {step === 5 && (
                                <div className='airdropcentercontent'>
                                    <h1>Task 4 to claim your airdrop</h1>
                                    <span>Find out if you're eligible to participate.</span>
                                    <p>Connect your TON wallet and perform the mentioned tasks to check if you are elligible for AngelPoop airdrop.</p>
                                    <div className="customcheckboxinsure">
                                        <input type="checkbox" className="inputCheckbox" id="claimcheck4" name="claimcheck4" value="yes" />
                                        <label htmlFor="claimcheck4">Task 4 to claim your airdrop</label>
                                    </div>
                                    <button type='button' onClick={nextStep}>Submit</button>
                                </div>
                            )} {step === 6 && (
                                <div className='airdropcentercontent'>
                                    <h1>Congratulations Poop Head</h1>
                                    {/* <h1>Oooops!</h1> */}
                                    <p>Congratulations Poop Head, you are eligible for AngelPoop token airdrop. You can now mint our limited edition AngelPoop OG badge for future rewards.</p>
                                    {/* <p>Unfortunately you are not eligible for the airdrop this time but stay tuned for future drops</p> */}                                    
                                    {/* <button type='button' onClick={nextStep}>Submit</button> */}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className='section_overlay_csscroll section_overlay_csscroll2'>
                    <marquee><img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop </marquee>
                </div>
            </section>
        </>
    );
}
export default ClaimSbt;